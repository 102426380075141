import React, {Component} from "react";
import {withRouter} from "react-router-dom";

import {
	Nav,
	Navbar,
	NavDropdown,
} from "react-bootstrap";

import nationalhighways from "../img/national_highways_logo.jpg";
import itree from "../img/i-tree-logo.png";

class Header extends Component {

	reset = (e) => {
		this.props.reset();
		this.props.history.push("/");
	};

	goBack = () => {
		let path = this.props.history.location.pathname;

		if (path === "/map") {
			this.props.history.push("/")
		} else if (path === "/report") {
			this.props.history.push("/map")
		} else {
			this.props.history.push("/")
		}
	};

	render() {
		return (
			<Navbar
				collapseOnSelect
				expand={"lg"}
				className={"mb-3"}
			>
				<Navbar.Brand
					className={"d-none d-md-block"}
					href={"https://nationalhighways.co.uk/"}
					target={"_blank"}
					rel={"noopener noreferrer"}
				>
					<img
						alt="National Highways logo"
						src={nationalhighways}
						className="d-none d-md-block"
					/>
				</Navbar.Brand>
				<Navbar.Brand
					className={"d-none d-md-block"}
					href={"https://www.itreetools.org/"}
					target={"_blank"}
					rel={"noopener noreferrer"}
				>
					<img
						alt="logo"
						src={itree}
						className="d-none d-md-block"
					/>
				</Navbar.Brand>
				<Navbar.Brand href={"#"}>
					<div className={"d-flex flex-column"}>
						<div>
						<h3 className={"d-none d-sm-inline"}>
							Ecosystem Break-Even Calculator
							<sub className={"small"}>
								{process.env.REACT_APP_VERSION}
							</sub>
						</h3>
						<h4 className={"d-inline d-sm-none"}>
							Break-Even Calculator
						</h4>
						</div>
						<div className={"tagline"}>
							Powered by i-Tree<sup>&#174;</sup>
						</div>
					</div>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls={"basic-navbar-nav"}/>
				<Navbar.Collapse id={"basic-navbar-nav"}>
					<Nav className={"mr-auto"}>
						<Nav.Link href={"/"}>
							Home
						</Nav.Link>
						<NavDropdown
							id={"navbar-dropdown"}
							title={"Project"}
							className={"disabled"}
							disabled={this.props.history.location.pathname === "/"}
						>
							<NavDropdown.Item
								onClick={
									(e) => this.reset(e)
								}
							>
								Start Over
							</NavDropdown.Item>
							<React.Fragment>
								<NavDropdown.Divider/>
								< NavDropdown.Item
									onClick={
										() => this.goBack()
									}
								>
									Go Back
								</NavDropdown.Item>
								{/*<NavDropdown.Item*/}
								{/*	disabled*/}
								{/*	onClick={*/}
								{/*		(e) => console.log(e)*/}
								{/*	}*/}
								{/*>*/}
								{/*	Export Data*/}
								{/*</NavDropdown.Item>*/}
								<NavDropdown.Item
									onClick={
										(e) => window.print()
									}
								>
									Print
								</NavDropdown.Item>
							</React.Fragment>

						</NavDropdown>
						<Nav.Link
							href={"https://www.itreetools.org/"}
							target={"_blank"}
							title={"Open the i-Tree Tools homepage in a new window."}
						>
							i-Tree
						</Nav.Link>
						<NavDropdown
							id={"feedback-dropdown"}
							className={"float-right"}
							title={"Feedback"}
						>
							<NavDropdown.Item
								href={"https://www.itreetools.org/feedback/"}
								title={"General Feedback"}
								target={"_blank"}
							>
								Comments<br/>
								<small>
									<em>
										Suggestions / Stories / Problems
									</em>
								</small>
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		)
	}
}

export default withRouter(Header);